<template>
  <div class="profile">
    <div class="top">
      <span @click="$router.push('/lang')" class="feedback">{{
        $t("en.ChangeLanguage")
      }}</span>
      <!-- <span @click="$router.push('answerList')" class="feedback" v-if="isLogin"
        >Feedback</span
      > -->
      <!-- <span class="logOut" v-show="isLogin" @click="logout">Log out</span> -->
      <span class="logOut" @click="switchAccount">{{
        $t("en.SwitchAccount")
      }}</span>
      <div class="infoBox">
        <div class="coverBox">
          <div class="cover">
            <img
              src="~/img/profile/default-avatar.png"
              v-if="!isLogin || !userAvatar"
            />
            <img :src="userAvatar" v-if="isLogin" />
          </div>
        </div>
        <span v-if="!isLogin" class="logIn" @click="login">{{
          $t("en.Login")
        }}</span>
        <h3 v-if="isLogin" class="name">{{ userNickname }}</h3>
      </div>

      <div class="backImg">
        <img src="~img/profile/personalCenter.png" alt="" />
      </div>
    </div>

    <div class="bottom">
      <Recharge></Recharge>
    </div>

    <Login :showLogin="showLogin" @closePopup="closePopup" />
  </div>
</template>

<script>
import Recharge from "@/components/Recharge";
import Login from "@/components/User/login";
import { userCenter } from "@/http/api";

export default {
  components: { Recharge, Login },
  data() {
    return {
      isLogin: false, // 是否登录
      userAvatar: "", // 用户头像
      userNickname: "", // 用户昵称
      showLogin: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    login() {
      // 登录
      this.showLogin = true;
    },
    closePopup() {
      // 关闭弹窗
      this.showLogin = false;
    },
    logout() {
      // 登出
      this.$cookie.set("token", "");
      window.location.reload();
    },
    switchAccount() {
      // 切换账号
      this.showLogin = true;
    },
  },
  created() {},
  mounted() {
    // 登录状态检查
    userCenter(JSON.parse(this.$cookie.get("requireParams"))).then((res) => {
      if (Object.keys(res.data.user).length) {
        this.isLogin = true;
        this.userAvatar = res.data.user.avatar;
        this.userNickname = res.data.user.nickname;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.profile{
  height: 100vh;
  overflow-y: hidden;
}
.scroll {
  width: 100vw;
  height: calc(100vh - 0.98rem);
  overflow-y: auto;
}
.top {
  width: 100%;
  position: relative;
  padding-top: 3.125rem;
}
.logOut {
  padding: 0 0.625rem;
  height: 1.5rem;
  background: #505061;
  border-radius: 0.78125rem;
  line-height: 1.5rem;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  position: absolute;
  top: 0.625rem;
  right: 0.9375rem;
  z-index: 222;
}
.logIn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.625rem;
  background: linear-gradient(96deg, #9b8cff, #5e45ff);
  border-radius: 1.0625rem;
  line-height: 2rem;
  margin: 0.625rem auto 0;
  display: block;
  height: 2rem;
  text-align: center;
  font-size: 1.125rem;
  color: #fff;
  font-weight: 700;
}
.coverBox {
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.infoBox {
  position: relative;
  z-index: 3;
}
.backImg {
  width: 100%;
  height: 16.15625rem;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.name {
  height: 2rem;
  text-align: center;
  font-size: 1.125rem;
  color: #fff;
  font-weight: 700;
  width: 100%;
  line-height: 1.5625rem;
  margin-top: 0.625rem;
}
.bottom {
  position: relative;
  z-index: 10;
  background: #fff;
  padding-top: 1.5rem;
  border-radius: 3rem 3rem 0 0;
  top: 1.5rem;
}
.feedback {
  padding: 0 0.625rem;
  height: 1.5rem;
  background: #505061;
  border-radius: 0.78125rem;
  line-height: 1.5rem;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  position: absolute;
  top: 0.625rem;
  left: 0.9375rem;
  z-index: 222;
}
</style>
